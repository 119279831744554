import { ParsedCreditCard } from "../../features/profile-slice";
import { AccountActivityType } from "../../screens/account-history-page";
import { AlertActivityType } from "../../screens/alerts-history-page";
import { DisconnectionSelection } from "../../screens/leave-reach-page";
import { Customer, CustomerStatus } from "./customer";
import { Limitations, LimitationsKey, PermissionKey, UserRolesRes } from "./user-roles";

export interface GenericRes<T> {
    data: T;
    status: string;
    exCode?: string;
    resCode?: number;
}

export interface SearchCustomerLastKey {
    id: string;
    modifiedDate: string;
}

export interface SearchCustomerReq {
    status?: CustomerStatus[];
    orderStatus?: string[];
    simPaid?: boolean;
    limit?: number;
    query?: string;
    lastKeys?: SearchCustomerLastKey;
    isDraft?: boolean;
    partialMatch?: boolean;
    limitedFieldSearch?: boolean;
}

export interface SearchCustomerRes {
    lastKeys?: SearchCustomerLastKey;
    results: SearchableCustomer[];
}

export type SearchableCustomer = Pick<
    Customer,
    | "activationDate"
    | "emailId"
    | "firstName"
    | "groupId"
    | "hubSpotId"
    | "id"
    | "imei"
    | "lastName"
    | "make"
    | "model"
    | "modifiedDate"
    | "isPrimary"
    | "primaryNumber"
    | "reachNumber"
    | "simPaid"
    | "source"
    | "status"
    | "userName"
    | "isDraft"
    | "isCardHold"
    | "leadSource"
> & {
    latestOrderStatus?: OrderStatus;
};

export type PortFailureCode = 1009 | 1010 | 1011 | 1017;

export enum BillingForecastItemType {
    CHARGE = "Charge",
    CREDIT = "Credit",
    DISCOUNT = "Discount",
    REFERRAL = "Referral",
    ADJUSTMENT = "Adjustment",
}

export interface EstimatedBill {
    amountToPay: number;
    billing: BillingItem[];
    coupon?: Coupon;
    nextBillCycle: boolean;
}

export type CustomerProfile = SearchableCustomer &
    Pick<
        Customer,
        | "isSimInHand"
        | "extraLines"
        | "additionalLines"
        | "zipcode"
        | "addresses"
        | "simId"
        | "autoTopUpStatus"
        | "operationsInProcess"
        | "isCDMALessEnabled"
        | "isMNP"
        | "totalTopupInGB"
        | "totalTopupCost"
        | "lastDisconnectedDate"
        | "appInfo"
        | "customerNumber"
        | "isThrottled"
        | "isCIDBEnabled"
        | "mnpInfo"
        | "nextBillingDate"
        | "billGenerationDate"
        | "billStartDate"
        | "billEndDate"
        | "skipBilling"
        | "laterDisconnectionDate"
    > & {
        creditCards?: ParsedCreditCard[];
        shipmentOrder?: ShipmentOrder;
        groupLines: Customer[];
        link?: PaymentLink;
        activation?: Activation;
        reachPlan: ReachPlan;
        referralCode?: string;
        referralLink?: string;
        estimatedBill?: EstimatedBill;
        isDataCutoff: boolean;
        isILDEnabled: boolean;
        isRoaming: boolean;
        loginProvider?: string;
        rating?: string;
        totalLatePayments: number;
        simPurchaseDate?: string;
        lastDisconnectedReason?: DisconnectionReason;
        portOutInfo?: PortoutInfo;
        ptpEligibility?: PromiseToPayEligibility;
        latestMDNAlert?: MDNAlert;
    };
export interface PortoutInfo {
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    accountNumber?: string;
    pin?: string;
    zipCode?: string;
    status?: PortoutStatus;
    reasonCode?: string;
    reasonDescription?: string;
    generatedPin?: string;
}

export enum PortoutStatus {
    CONFIRMED = "CONFIRMED",
    DELAYED = "DELAYED",
    RESOLUTIONREQUIRED = "RESOLUTIONREQUIRED",
    COMPLETED = "COMPLETED",
}

export enum DisconnectionReason {
    TRIGGER_BY_USER = 0,
    BILL_DUE = 1,
    PORTED_OUT = 2,
    REQUESTED_BY_CS = 3,
}

export interface PromiseToPayEligibility {
    isEligible: boolean;
    toBeDisconnected?: string;
    toBeHotlined?: string;
}

export interface Activation {
    status?: CustomerStatus;
    portFailureCode?: PortFailureCode;
    activationMsg?: string;
    suggestedMsg?: string;
    mvneStatus?: string;
}

export interface ShipmentOrder {
    orderId: string;
    trackingNumber?: string;
    orderDate: string;
    labelledOn?: string;
    shippedOn?: string;
    deliveredOn?: string;
    orderCanceledOn?: string | number;
    estimatedDeliveryDate?: string;
    orderNumber: string;
    orderStatus: OrderStatus;
    shippingAmount: number;
    taxAmount: number;
    deliveryDays?: number;
    category?: string;
    trackingUrl?: string;
    items: {
        quantity: number;
        orderItemId: number;
        sku: string;
        name: string;
        unitPrice: number;
    }[];
}

export enum OrderStatus {
    ORDERED = "ORDERED",
    LABELLED = "LABELLED",
    SHIPPED = "SHIPPED",
    INTRANSIT = "INTRANSIT",
    DELIVERED = "DELIVERED",
    CANCELLED = "CANCELLED",
}

interface PaymentLink {
    url: string;
    type: PaymentLinkType;
    createdDate: number;
    expireDate: number;
}

export enum PaymentLinkType {
    SIM_PURCHASE = 1,
    ADD_CARD = 2,
    SINGLE_BILL_PAYMENT = 3,
    MULTIPLE_BILL_PAYMENT = 4,
}

export interface ReachPlan {
    name: string;
    dataUnit: string;
    planData: number;
    talktime: number;
    text: number;
    currency: string;
    countryCode: string;
    baseLinePrice: number;
    additionalLinePrice: number;
    discountPctg: number;
    planStatus: number;
    maxLines: number;
    isUnlimited: boolean;
    addLineSplitPrice?: { [key: string]: number };
    isLimitedOffer: boolean;
}

export interface ImeiRes {
    isValid: boolean;
    make: string;
    model: string;
    meta?: Record<string, string[]>;
    errorText?: string;
    mode?: string;
}

export enum TaxType {
    SIM = 1,
    PLAN = 2,
    DEVICE = 3,
    TOPUP = 4,
    ILD_VOICE = 8,
    ILD_SMS = 9,
}

export interface TaxRes {
    amount: number;
    customerId?: string;
    taxId: string;
    totalTax: number;
    taxes: {
        cat: string;
        name: string;
        tax: number;
    }[];
}

export interface SimCostRes {
    countryCode: string;
    cost: number;
    discountPctg: number;
    subsequentCost: number;
}

export interface MNPValidateRes {
    isPortValidate: boolean;
    message?: string;
}

export enum CouponType {
    EMPLOYEE = 1,
    EARLYBIRD = 2,
    REFERRAL = 3,
    BETA = 4,
    PROMO = 5,
}

export interface Coupon {
    coupon: string;
    delayByMonths?: number;
    expired: boolean;
    maxDiscountInDollar?: number;
    name: string;
    planDiscount?: number;
    secondaryDiscount?: number;
    skipValidity: boolean;
    status: number;
    subType: number;
    type: CouponType;
    validityInMonths?: number;
    refereeDiscInDollar?: number;
    refereeDiscount?: number;
    discountInDollar?: number;
}

enum CardStatus {
    ACTIVE = 0,
    INACTIVE = 1,
}

export interface CreditCard {
    uuid: string;
    type: string;
    ccNumberMask: string;
    isAutoPay: boolean;
    dateOfExpiry: string;
    status: CardStatus;
    priority: number;
    nameOnCard: string;
    createdDate: string;
    customerId: string;
}

export interface IccIdRes {
    isValid: boolean;
    msg: string;
}

export interface ActivationReq {
    customerId: string;
    iccId: string;
    imei: string;
    reachPlanId: string;
    make: string;
    model: string;
    skipHold: boolean;
    isPort: boolean;
    numberToPort?: string;
    oldCarrier?: string;
    oldCarrierAccountNumber?: string;
    password?: string;
    oldZip?: string;
}

export interface CardHoldRes {
    holdStatus: boolean;
}

export enum UpdatePortType {
    UPDATE_PORT = "UPDATE_PORT",
    RESUBMIT_PORT = "RESUBMIT_PORT",
    CANCEL_AND_RESUBMIT = "CANCEL_AND_RESUBMIT",
    ALREADY_UPDATED = "ALREADY_UPDATED",
    CANCEL_PORT = "CANCEL_PORT",
    CANCEL_AND_NEW = "CANCEL_AND_NEW",
}

export interface UpdatePortReq {
    type: UpdatePortType;
    numberToPort?: string;
    oldCarrier?: string;
    oldCarrierAccountNumber?: string;
    oldZip?: string;
    password?: string;
    imei?: string;
    iccId?: string;
}

export interface ChangePlanReq {
    customerId: string;
    newReachPlanId: string;
    immediateEffect: boolean;
}

export interface AutoTopupReq {
    primaryId: string;
    customerAutoTopUpMap: Record<string, boolean>;
}

export interface CDRDataUsageRes {
    [date: string]: {
        users: {
            userId: string;
            phoneNumber: string;
            bytes: number;
            billMonth: string;
            maxBytes: number;
            extraBytes: number;
            topupBytes?: number;
            isCurrentBillingDate: boolean;
        }[];
        group?: {
            groupId: string;
            bytes: number;
            billMonth: string;
            maxBytes: number;
            extraBytes: number;
            topupBytes?: number;
            isCurrentBillingDate: boolean;
            isProrated?: boolean;
        };
    };
}

export interface TopupPulseRes {
    pulseCost: number;
    pulse: number;
    limit: number;
    recipientPulseCost: number;
}

export enum ManageFeatureOperation {
    ADD_THROTTLE = "ADD_THROTTLE",
    REMOVE_THROTTLE = "REMOVE_THROTTLE",
    HOTLINE = "HOTLINE",
    SUSPEND = "SUSPEND",
    RESTORE = "RESTORE",
    ADD_CARD_HOLD = "ADD_CARD_HOLD",
    RELEASE_CARD_HOLD = "RELEASE_CARD_HOLD",
    ADD_DATA_CUTOFF = "ADD_DATA_CUTOFF",
    REMOVE_DATA_CUTOFF = "REMOVE_DATA_CUTOFF",
    REMOVE_CUSTOMER = "REMOVE_CUSTOMER",
}

export enum ManageFeatureReason {
    OVERUSE = "OVERUSE",
    OTHER = "OTHER",
    BILLDUE = "BILLDUE",
    BILLPAID = "BILLPAID",
    PORTOUT = "PORTOUT",
}

export interface ManageFeatureReq {
    operation: ManageFeatureOperation;
    reason: ManageFeatureReason;
    groupAction: boolean;
}

export enum ManageFeatureStatus {
    PROGRESS = 1,
    SUCCESS = 2,
    FAILED = 3,
}

export interface ManageFeatureRes {
    status: ManageFeatureStatus;
    failureReasonMap?: Record<string, string>;
}

export interface TopupReq {
    customerId: string;
    totalData: string;
    taxAmount?: string;
    taxId?: string;
    taxInfo?: TaxRes;
    ccUUID?: string;
    isChargable: boolean;
}

export type TopupHistoryRes = {
    customerId: string;
    topupDate: string;
    totalCost: number;
    totalData: number;
    billMonth: string;
    status: "PURCHASED" | "CANCELLED" | "REFUNDED" | "DUE" | "GIFTED";
    isAutoTopUp?: boolean;
}[];

export enum NetworkResetStatus {
    SUSPEND_IN_PROGRESS = "SUSPEND_IN_PROGRESS",
    RESTORE_IN_PROGRESS = "RESTORE_IN_PROGRESS",
    RESET_FAILED = "RESET_FAILED",
    CUSTOMER_IS_ACTIVE = "CUSTOMER_IS_ACTIVE",
}

export interface CustomizedBill {
    amountToPay: number;
    billPaid: boolean;
    invoiceUrl: string;
    billing: BillingItem[];
}

export interface BillingItem {
    title: string;
    type: string;
    rightText?: string;
    amount?: number;
    subtitle?: string;
    actualAmount?: number;
    bestfit?: boolean;
    details?: BillingDetail[];
    prorated?: boolean;
}

export interface BillingDetail {
    amount: number;
    subtitle: string;
    type: string;
}

export interface ReferralAnalytics {
    referrerDiscInDollar: number;
    customers: Record<string, ReferralCustomers>;
    refereeDiscInDollar: number;
    discountInDollar: ReferralDiscountInDollar;
    referralMaxDiscInDollar: number;
}

export interface ReferralCustomers {
    signedUp: ReferralDetail[];
    inviteAccepted: ReferralDetail[];
    activated: ActivatedReferralDetail[];
    referrerDiscInDollar: number;
    refereeDiscInDollar: number;
}

export interface ActivatedReferralDetail {
    emailId: string;
    date: string;
    name: string;
    maxDiscInDollar: number;
    startDate: string;
}

export interface ReferralDetail {
    emailId: string;
    date: string;
    name: string;
}

export interface ReferralDiscountInDollar {
    applied: number;
    remaining: number;
    total: number;
}

export interface ZipcodeRes {
    City: string;
    CoveragePercent: number;
    Id: string;
    RateCenter: string;
    State: string;
    Zip: string;
    isValid: boolean;
}

export interface DiscountRes {
    totalAvailable: number;
    totalUsed: number;
    totalEarned: number;
    discounts?: DiscountInfo[];
}

export interface DiscountInfo {
    name: string;
    code?: string;
    available?: number;
    used: number;
    earned?: number;
    startDate: string | number;
    createdDate: string | number;
    expireDate?: string | number;
    discountInDollar?: number;
    primaryDiscount?: number;
    secondaryDiscount?: number;
    lastModifiedDate?: string | number;
    type: string;
}

export interface AccountSwapReq {
    groupId: string;
    primaryCustomerId: string;
    secondaryCustomerId: string;
    newPrimaryPreferredEmailId: string;
}

export interface AddCreditReq {
    customerIds: string[];
    creditAmount: string;
    csId: string;
    description: string;
    validityInMonths?: string;
    startDate?: string;
}

export interface CDRReportReq {
    startDate: string;
    endDate: string;
    groupAction: boolean;
    reportTypes: (
        | "cs_cdr_reports_domestic_calls"
        | "cs_cdr_reports_domestic_texts"
        | "cs_cdr_reports_domestic_datas"
        | "cs_cdr_reports_intl_calls"
        | "cs_cdr_reports_intl_texts"
        | "cs_cdr_reports_intl_romaing_calls"
        | "cs_cdr_reports_intl_romaing_texts"
        | "cs_cdr_reports_intl_romaing_datas"
        | "cs_cdr_reports_domestic_roaming_calls"
        | "cs_cdr_reports_domestic_roaming_datas"
        | "cs_cdr_reports_mms"
    )[];
}

export interface CreditPurchaseReq {
    primaryId: string;
    customerCreditMap:
        | {
              [customerId: string]: number;
          }
        | Map<string, number>;
    ccUUID: string;
    totalAmount: number;
    currency?: "USD";
    creditType: "ILD" | "IR";
    enableFeature: boolean;
}

export interface CreditPurchaseRes {
    [customerId: string]:
        | {
              status: CreditPurchaseStatus;
              availableCredit: number;
          }
        | undefined;
}

export enum CreditPurchaseStatus {
    ACTIVATION_IN_PROGRESS = 0,
    ACTIVATED = 1,
    ACTIVATION_FAILED = 2,
    DEACTIVATED = 3,
}

export interface IldStatusRes {
    status: CreditPurchaseStatus;
}

export interface ILDUsageRes {
    [endMonth: string]: {
        billStartDate: string;
        billEndDate: string;
        usageDetail: {
            [customerId: string]: {
                countryUsageMap?: {
                    [countryCode: string]: {
                        voiceUsage: number;
                        voiceCost: number;
                        totalCost: number;
                        ratePerMin: number;
                        ratePerPulse: number;
                    };
                };
                voicePulseSize?: number;
                totalUsage: {
                    voiceUsage?: number;
                    smsUsage?: number;
                    voiceCost?: number;
                    smsCost?: number;
                    totalCost?: number;
                    serviceCharge: number;
                };
            };
        };
    };
}

export interface IntlCountry {
    countryCode: string;
    countryName: string;
    createdDate: string;
    dialingCode: string;
    ildSmsOut: number;
    ildStatus: number;
    ildVoiceOut: number;
    modifiedDate: string;
    mvne: string;
    pricePerPulse: number;
    smsIn: number;
    smsOut: number;
    status: number;
    voiceIn: number;
    voiceOut: number;
    zone: string;
}

export interface InternationalCountriesRes {
    countriesList: IntlCountry[];
    dataPulse: number;
    ildAutoCreditAmount: number;
    ildIncrCreditAmount: number;
    ildMaxCreditAmount: number;
    ildMinCreditAmount: number;
    ildPulseSize: number;
    ildPulseUnit: "Min";
    ildServiceCharge: number;
    ildThresholdAmount: number;
    maxNoOfPulses: number;
    minZoneCost: number;
}

export interface IntlMaxCountry {
    code: string;
    ildEnabled: boolean;
    irEnabled: boolean;
    maxCostForData: number;
    maxCostForSmsIn: number;
    maxCostForSmsOut: number;
    maxCostForVoiceIn: number;
    maxCostForVoiceOut: number;
    maxIldCostForSmsOut: number;
    maxIldCostForVoiceOut: number;
    name: string;
    zone: string;
}

export interface MaxInternationalCountriesRes {
    countriesList: IntlMaxCountry[];
}

export interface CDRReportRes {
    customerId: string;
    billMonth: string;
    phoneNumber: string;
    filePath: string;
    startDate: string;
    endDate: string;
}

export interface HubspotEngagementNotesReq {
    contactIds: number[];
    message: string;
}

export interface OrderReq {
    cId: string;
    reachCarrierId: string;
    deliveryDays: number;
    serviceType: string;
    serviceCode: string;
    carrierCode: string;
    category: "SIM";
    shippingAmount: number;
    estimatedDeliveryDate: string;
    taxAmount: number;
    totalAmount: number;
    taxId?: string;
    ccUUID: string;
    secondaryCustomers: string[];
    shipTo: {
        city: string;
        country: "US";
        name: string;
        phone: string;
        postalCode: string;
        residential: "false";
        state: string;
        street1: string;
    };
    items: {
        quantity: number;
        unitPrice: number;
    }[];
}

export enum PortRejectReason {
    CUSTOMER_INFORMATION_DOES_NOT_MATCH,
    ACCOUNT_NUMBER_REQUIRED_OR_INCORRECT,
    SSN_TAX_ID_REQUIRED_OR_INCORRECT,
    PASSWORD_PIN_REQUIRED_OR_INCORRECT,
    ZIP_CODE_REQUIRED_OR_INCORRECT,
    FIRST_NAME_REQUIRED_OR_INCORRECT,
    LAST_NAME_REQUIRED_OR_INCORRECT,
    BUSINESS_NAME_REQUIRED_OR_INCORRECT,
    MDN_NOT_ACTIVE,
    PORT_COMPLEXITY,
    OTHER,
}

export interface LeaveReachReq {
    reason?: string;
    customers: Record<
        string,
        {
            type: DisconnectionSelection;
            laterDisconnectionDate?: string;
        }
    >;
}

export interface ReconnectionReq {
    customerId: string;
    skipReconnectFee: boolean;
    isPortIn: boolean;
    portInInfo?: {
        numberToPort: string;
        oldCarrier: string;
        oldCarrierAccountNumber: string;
        oldZip: string;
        password?: string;
    };
}

export enum UpdatePortoutType {
    RESOLUTION_REQUIRED = "RESOLUTION_REQUIRED",
    CONFIRMED = "CONFIRMED",
}
export interface UpdatePortoutReq {
    responseType: UpdatePortoutType;
    reasonCode?: PortRejectReason;
}

export type OpsEventsPayload = OpsEvent[];

export interface OpsEvent {
    event: EventName;
    session_id: string;
    status: "SUCCESS" | "FAILURE";
    description?: string;
    event_type: "query" | "action" | "complaint";
    event_category: string;
    event_subcategory?: string;
    customer_id?: string;
    customer_email?: string;
    user?: string;
    user_type: "cs";
    image_path?: string;
    location?: {
        latitude: number;
        longitude: number;
    };
    device_details?: {
        device?: string;
        agent?: string;
        resolution?: string;
    };
    event_params?: Record<string, string>;
}

export enum EventName {
    Query = "query",
    Request = "request",
    Complaint = "complaint",
}

export interface TenantAccount {
    id: string;
    tenantId: string;
}

export type QRCCategoriesRes = Record<EventName, Record<string, string[]>>;

export type PermissionsRes = (Permission | PermissionWithLimitation)[];

export interface Permission<T = PermissionKey> {
    id: T;
    subCategoryId: string;
    subCategoryName: string;
    operationId: string;
    operationName: string;
    categoryId: string;
    categoryName: string;
    createdDate: number;
    modifiedDate: number;
    isActive: boolean;
}

export interface PermissionWithLimitation extends Permission<LimitationsKey> {
    id: LimitationsKey;
    supportedLimitations?: Limitations[LimitationsKey];
}

export type SearchableUser = Partial<UserRolesRes> &
    Pick<UserRolesRes, "accountId" | "emailId" | "firstName" | "lastName" | "phoneNumber" | "countryCode" | "roleIds">;

export interface SearchUserReq {
    limit?: number;
    query?: string;
    lastKeys?: SearchCustomerLastKey;
    roleIds: string[];
}

export interface SearchUserRes {
    lastKeys?: SearchCustomerLastKey;
    results: SearchableUser[];
}

export interface CreateUserReq {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    countryCode: string;
    emailId: string;
    accountId: string;
    roleIds: string[];
}

export interface CancelTopupRes {
    cancelledDataCost: number;
    cancelledDataInGBs: number;
    requestedBy: string;
    totalCancelledDataCost: number;
    totalCancelledDataInGBs: number;
}

export interface RefundTopupRes {
    refundDataCost: number;
    refundedDataInGBs: number;
    requestedBy: string;
    totalRefundedDataCost: number;
    totalRefundedDataInGBs: number;
}

export interface ImeiInfoRes {
    isValid: boolean;
    make: string;
    model: string;
    mode: string;
    wifiCalling: string;
    isLostOrStolen: string;
    isPhoneInBoxLocked: string;
    hdVoice: string;
    discountLevel: string;
    cdmaLess: string;
    deviceIsInUseFromReachOnVerizon?: string;
}

export interface AddonChargeReq {
    creditAmount: number;
    customerId: string;
    description: string;
}

export interface NewToPortReq {
    oldZip: string;
    oldCarrier: string;
    oldCarrierAccountNumber: string;
    numberToPort: string;
    pin: string;
}

type CountriesMap = Record<string, CountryData>;

export interface CountryData {
    countryCode: string;
    countryName: string;
    createdDate: string;
    dialingCode: string;
    modifiedDate: string;
    mvne: string;
    pricePerPulse: number;
    smsIn: number;
    smsOut: number;
    status: number;
    voiceIn: number;
    voiceOut: number;
    zone: string;
}

export enum TripStatus {
    INACTIVE,
    ACTIVATED,
    DEACTIVATED,
    CANCELLED,
    COMPLETED,
    ACTIVATION_FAILED,
}

export interface IRTrip {
    id?: string;
    countriesList?: string[];
    countriesMap?: CountriesMap;
    createdDate?: string;
    customerGroupId?: string;
    customerId?: string;
    dataCap?: number;
    dataPulse?: number;
    deactivationReason?: TripDeactivationReason;
    endedReason?: string;
    firstCDRDate?: string;
    groupId?: string;
    irDisabledDates?: string[];
    irEnabledDates?: string[];
    lastCDRDate?: string;
    modifiedDate?: string;
    paygoTrip?: number;
    primaryCustomerId?: string;
    startDate?: string;
    status?: TripStatus;
    availableCredits?: number;
    minimumCreditsToBePurchased?: number;
    tentativeStartDate?: string;
    totalZoneUsage?: number;
    totalPurchasedCredit?: number;
    carryForwardedCredit?: number;
    adjustedCredits?: number;
    activationInProgress?: boolean;
    prepaid?: boolean;
    travelledNSCountries?: string[];
    activationFailureReason?: string;
    skipInfo?: AutoDeactivationStatusReq;
}

export interface EstimateTripRes {
    availableCredits: number;
    minimumTripCredits: number;
}

interface ZoneUsage {
    data?: {
        usage?: number;
        pulse?: string;
        pulseCost?: number;
        cost?: number;
    };
    voiceOut?: {
        usage?: number;
        pulse?: string;
        pulseCost?: number;
        cost?: number;
    };
    voiceIn?: {
        usage?: number;
        pulse?: string;
        pulseCost?: number;
        cost?: number;
    };
    smsIn?: {
        usage?: number;
        pulse?: string;
        pulseCost?: number;
        cost?: number;
    };
    smsOut?: {
        usage?: number;
        pulse?: string;
        pulseCost?: number;
        cost?: number;
    };
}

export interface IRDataUsageRes {
    overall?: {
        totalCost: number;
        zoneUsage: ZoneUsage;
        paygoUsage: Record<string, Exclude<ZoneUsage, "smsIn" | "smsOut">>;
    };
}

export interface PayInAdvanceReq {
    customerId: string;
    advancePayAmount: number;
    description: string;
}

export interface InstantChargeReq {
    customerId: string;
    purchasedCredit: number;
    description: string;
}

export interface PlanHistoryItem {
    billingDate: string | number;
    createdDate: string | number;
    currentReachPlanId: string;
    customerGroupId: string;
    customerId: string;
    immediateEffect: boolean;
    newReachPlanId: string;
    upgraded: boolean;
    cancelled: boolean;
}

export interface MDNAlert {
    customerId: string;
    createdDate: string;
    activity: keyof typeof AlertActivityType;
    reason: string;
    status: boolean;
    txnId: string;
    modifiedDate: string;
    rectificationMode?: "manual" | "automatic";
}

export interface AccountHistory {
    customerId: string;
    groupId: string;
    createdDate: string;
    activity: keyof typeof AccountActivityType;
    description: string;
    success: boolean;
    emailId: string;
}

export interface PromiseToPayHistory {
    customerId: string;
    createdDate: string;
    billId: string;
    billMonth: string;
    emailId: string;
    actualBillAmount: number;
    ptpBillAmount: number;
    billDueDate: string;
    ptpScheduledDate: string;
    billPaid: boolean;
    status: string;
    valid: boolean;
}

export type MDNAlertsRes = MDNAlert[];
export type AccountHistoryRes = AccountHistory[];
export type PromiseToPayHistoryRes = PromiseToPayHistory[];

export interface AutoDeactivationStatusReq {
    deactivationByLocalCDR?: boolean;
    deactivationByPaygoCDR?: boolean;
    deactivationByNoCDR?: boolean;
    cancellationByNoCDR?: boolean;
}

export enum TripDeactivationReason {
    TRIGGERED_BY_USER = "TRIGGERED_BY_USER",
    LOCAL_CDR = "LOCAL_CDR",
    PAYGO_CDR = "PAYGO_CDR",
    DATA_CAPPING = "DATA_CAPPING",
    NO_IR_CDR = "NO_IR_CDR",
    IR_PACK_UPGRADE = "IR_PACK_UPGRADE",
    BILL_DUE = "BILL_DUE",
    ROAMING_ALREADY_ENABLED = "ROAMING_ALREADY_ENABLED",
    INACTIVE_CUSTOMER = "INACTIVE_CUSTOMER",
    FAILED_BY_MVNE = "FAILED_BY_MVNE",
    NO_PACK_INFO = "NO_PACK_INFO",
    BACKEND_ERROR = "BACKEND_ERROR",
    INVALID_TRIP_STATE = "INVALID_TRIP_STATE",
    TRIGGERED_BY_CS = "TRIGGERED_BY_CS",
    REMOVE_THROTTLE_FAILED = "REMOVE_THROTTLE_FAILED",
    DISCONNECT_CUSTOMER = "DISCONNECT_CUSTOMER",
    PORTED_OUT = "PORTED_OUT",
    AVAILABLE_CREDIT_IS_LESS_THAN_MIN_CREDIT = "AVAILABLE_CREDIT_IS_LESS_THAN_MIN_CREDIT",
}

export interface PaymentPageReq {
    emailId: string;
    redirectUrl: string;
    type: "card" | "purchase";
}

export interface PaymentPageRes extends PaymentPageReq {
    id: string;
}

export interface TicketReq {
    subject: string;
    description: string;
    id: string;
    channel: string;
    contact: Contact;
    cf: CustomFields;
    email: string;
    priority: string;
    departmentId: string;
    category: string;
    subCategory: string;
    secondaryContacts?: string[];
}

export interface CustomFields {
    cf_issue_category?: string;
    cf_list_of_troubleshooting_steps_already_performed?: string;
    cf_issue_transaction_id?: string;
    cf_mdn: string;
    cf_customer_id: string;
    cf_account_id: string;
}

export interface Contact {
    firstName: string;
    lastName: string;
    email: string;
}

export interface TicketResData {
    subject: string;
    description: string;
    id: string;
    channel: string;
    contactId: string;
    contact: Contact;
    ticketNumber: string;
    cf: CustomFields;
    email: string;
    dueDate: string;
    priority: string;
    departmentId: string;
    status: string;
    createdTime: string;
    modifiedTime: string;
    closedTime?: string;
}

export interface TicketRes extends TicketResData {
    data: TicketResData[];
    count: number;
    currentCount: number;
    id: string;
}

export interface TicketPayload {
    departmentID: string;
    limit: string;
    query?: string;
    subject?: string;
    update?: boolean;
    createdTimeRange: string;
    status?: string;
    from?: number;
}

export interface TicketCommentReq {
    isPublic?: "true";
    attachmentIds?: string[];
    contentType: "html" | "plainText";
    content: string;
}

export interface TicketComment {
    isPublic: string;
    contentType: "html" | "plainText";
    content: string;
    id: string;
    commenter: Commenter;
    commenterId: string;
    commentedTime: string;
    modifiedTime: string;
}

export interface Commenter {
    id: string;
    name: string;
    email: string;
    photoURL: string;
    type: string;
    firstName: string;
    lastName: string;
    roleName: string;
}

export interface TicketAttachment {
    size: string;
    name: string;
    id: string;
    href: string;
    creatorId: string;
    createdTime: string;
}
